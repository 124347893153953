<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 mx-auto">
        <div class="form card card-body border-0">
          <h2 class="title1 mt-3 mb-3">初回設定</h2>
          <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header" role="tablist">
              <div class="step active" data-target="#test-l-1">
                <button id="stepper1trigger1" aria-controls="test-l-1" aria-selected="false"
                        class="step-trigger"
                        disabled="disabled" role="tab" type="button">
                  <span class="bs-stepper-circle">1</span>
                </button>
              </div>
              <div class="bs-stepper-line"></div>
              <div class="step" data-target="#test-l-2">
                <button id="stepper1trigger2" aria-controls="test-l-2" aria-selected="false"
                        class="step-trigger"
                        disabled="disabled"
                        role="tab" type="button">
                  <span class="bs-stepper-circle">2</span>
                </button>
              </div>
              <div class="bs-stepper-line"></div>
              <div class="step" data-target="#test-l-3">
                <button id="stepper1trigger3" aria-controls="test-l-3" aria-selected="false"
                        class="step-trigger"
                        disabled="disabled" role="tab" type="button">
                  <span class="bs-stepper-circle">3</span>
                </button>
              </div>
            </div>
            <div class="bs-stepper-content mt-4">
              <div id="test-l-1" aria-labelledby="stepper1trigger1" class="bs-stepper-pane active" role="tabpanel">
                <h2 class="mt-5 title2 mb-4">初回設定</h2>
                <div class="row">
                  <div class="col mt-4">
                    <label for="lastName">姓</label>
                    <input id="lastName" v-model="lastName" class="form-control" name="lastName" type="text" autocomplete="autocomplete_off_hack_xfr4!kln">
                  </div>
                  <div class="col mt-4">
                    <label for="firstName">名</label>
                    <input id="firstName" v-model="firstName" class="form-control" name="firstName" type="text" autocomplete="autocomplete_off_hack_xfr4!kfn">
                  </div>
                </div>
                <div class="row">
                  <div v-show="errors['lastName']" id="err_lastName" class="form-error">{{ errors['lastName'] }}</div>
                  <div v-show="errors['firstName']" id="err_firstName" class="form-error">{{
                      errors['firstName']
                    }}
                  </div>

                </div>
                <div class="form-group">
                  <label for="inputNewPassword">新しいパスワード</label>
                  <input id="inputNewPassword" v-model="password" class="form-control" name="newPassword"
                         placeholder="半角英数字・記号、8〜20文字" type="password">
                </div>
                <div class="row">
                  <div v-show="errors['password']" id="err_password" class="form-error">{{ errors['password'] }}</div>
                </div>
                <div class="form-group">
                  <label for="inoutConfirmNewPassword">新しいパスワードの確認</label>
                  <input id="inoutConfirmNewPassword" v-model="passwordConfirm" class="form-control"
                         name="passwordConfirm" type="password">
                </div>
                <div class="row">
                  <div v-show="errors['passwordConfirm']" id="err_passwordConfirm" class="form-error">
                    {{ errors['passwordConfirm'] }}
                  </div>
                </div>
                <div class="row mx-auto mt-5">
                  <button
                      class="btn btn-light bg-white ashen-link text-decoration-none light border-0 col-sm-6 order-1 order-sm-0"
                      v-on:click="goBack()">
                    キャンセル
                  </button>
                  <button class=" btn col btn-primary btn-outline-light font-weight-bold col-sm-6 order-0 order-sm-1"
                          v-on:click="validateAndUpdateUser(2)">次へ
                  </button>
                </div>
              </div>
              <div id="test-l-2" aria-labelledby="stepper1trigger2" class="bs-stepper-pane" role="tabpanel">
                <div>
                  <label class="mt-2">性別</label>
                </div>
                <div class="form-check form-check-inline col-12 col-sm-2">
                  <input id="genderFemale" v-model="gender" checked class="form-check-input" name="gender" type="radio"
                         value="2">
                  <label class="form-check-label" for="genderFemale">女性</label>
                </div>
                <div class="form-check form-check-inline col-12 col-sm-2">
                  <input id="genderMale" v-model="gender" class="form-check-input" name="gender" type="radio" value="1">
                  <label class="form-check-label" for="genderMale">男性</label>
                </div>
                <div class="form-check form-check-inline col-12 col-sm-3">
                  <input id="genderDoNotAnswer" v-model="gender" class="form-check-input" name="gender" type="radio"
                         value="3">
                  <label class="form-check-label" for="genderDoNotAnswer">回答しない</label>
                </div>
                <div v-show="errors['gender']" id="err_sex" class="form-error"><br/>{{ errors['gender'] }}</div>
                <div class="form-group">
                  <label for="inputCountryOfCitizenship">国籍
                  </label>
                  <select id="inputCountryOfCitizenship" v-model="nationalityId"
                          class="form-control form-select citizenship">
                    <option v-for="obj in nationalityList"
                            v-bind:value="obj.nationalityId">{{ obj.nationalityName }}
                    </option>
                  </select>
                </div>
                <div v-show="errors['nationalityId']" id="err_country" class="form-error">{{ errors['nationalityId'] }}
                </div>

                <div class="form-group">
                  <label for="inputStreetAddress">住所
                  </label>
                  <input id="inputStreetAddress" v-model="address" class="form-control" type="text">
                  <div class="row">
                    <div v-show="errors['address']" id="err_address" class="form-error">{{ errors['address'] }}</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputPhoneNumber">電話番号
                  </label>
                  <input id="inputPhoneNumber" v-model="phoneNo" class="form-control" type="text">
                  <div v-show="errors['phoneNo']" id="err_phone" class="form-error">{{ errors['phoneNo'] }}</div>
                </div>
                <br>
                <div class="row mx-auto mt-4">
                  <button
                      class="btn btn-light bg-white ashen-link text-decoration-none light border-0 col-12 col-sm-6 order-1 order-sm-0"
                      v-on:click="doCancel(1)">
                    戻る
                  </button>
                  <button
                      class="btn col btn-primary btn-outline-light font-weight-bold col-12 col-sm-6 order-0 order-sm-1"
                      v-on:click="validateAndUpdateUser(3)">
                    次へ
                  </button>
                </div>
              </div>
              <div id="test-l-3" aria-labelledby="stepper1trigger3" class="bs-stepper-pane" role="tabpanel">
                <!--
                <div><label class="mt-2">新型コロナワクチン接種
                </label>
                <div class="row  mx-auto mt-0 mt-sm-4">
                  <select v-model="coronaVaccinationCount" class="form-control form-select citizenship">
                  <option :value="0">なし</option>
                  <option :value="1">1回目</option>
                  <option :value="2">2回目</option>
                  <option :value="3">3回目</option>
                  <option :value="4">4回目</option>
                  <option :value="5">5回目</option>
                  <option :value="6">6回目</option>
                  <option :value="7">7回目</option>
                  <option :value="8">8回目</option>
                  <option :value="9">9回目</option>
                  <option :value="10">10回目</option>
                  </select>
                </div>
              </div>
                <div v-show="errors['hasCoronaVaccination']" id="err_coronaVaccinat" class="form-error">
                  <br/>{{ errors['hasCoronaVaccination'] }}
                </div>

                <div class="form-group">
                  <label for="lastInoculationDate">最後に接種した日
                  </label>
                  <Calender :currentDate="latestCoronaVaccinationDate"
                            :disable="coronaVaccinationCount == 0||coronaVaccinationCount == null"
                            :type="'vaccination'"
                            @update="updateCoronaDate"
                  ></Calender>

                  <div v-show="errors['latestCoronaVaccinationDate']" id="err_lastInoculatinoDate" class="form-error">
                    {{ errors['latestCoronaVaccinationDate'] }}
                  </div>
                </div>

                <div class="form-group"><label class="">PCR検査
                </label>
                </div>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input id="pcrTestPositive" v-model="latestPcrTestResult" checked class="form-check-input"
                           name="pcrTest" type="radio"
                           value="1">
                    <label class="form-check-label" for="pcrTestPositive">陽性</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="pcrTestNegative" v-model="latestPcrTestResult" class="form-check-input" name="pcrTest"
                           type="radio"
                           value="-1">
                    <label class="form-check-label" for="pcrTestNegative">陰性</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="pcrTestNotInspected" v-model="latestPcrTestResult" class="form-check-input"
                           name="pcrTest"
                           type="radio"
                           value="0">
                    <label class="form-check-label" for="pcrTestNotInspected">未検査</label>
                  </div>
                </div>

                <div v-show="errors['latestPcrTestResult']" id="err_pcrTest" class="form-error">
                  {{ errors['latestPcrTestResult'] }}
                </div>

                <div class="form-group">
                  <label for="lastInspectionDate">最後に検査した日
                  </label>
                  <Calender
                      :currentDate="latestPcrTestDate"
                      :disable="latestPcrTestResult == 0"
                      :type="'pcr'"
                      @update="updateDate"
                  ></Calender>

                  <div v-show="errors['latestPcrTestDate']" id="err_lastInspectionDate" class="form-error">
                    {{ errors['latestPcrTestDate'] }}
                  </div>
                </div>
                -->
                <div class="medical-history m-t-45">
                  <label>既往歴について</label>
                  <div class="form-check ml-2 mx-auto p-t-10">
                    <input
                        id="anemia"
                        v-model="anemia"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="anemia">貧血</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="asthma"
                        v-model="asthma"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="asthma">ぜんそく</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="heartDisease"
                        v-model="heartDisease"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="heartDisease">心臓病</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="kidneyDisease"
                        v-model="kidneyDisease"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="kidneyDisease">腎臓病</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="epilepsy"
                        v-model="epilepsy"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="epilepsy">てんかん</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="highBloodPressure"
                        v-model="highBloodPressure"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="highBloodPressure">高血圧</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="diabetes"
                        v-model="diabetes"
                        class="form-check-input"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="diabetes">糖尿病</label>
                  </div>
                  <div class="form-check ml-2 mx-auto m-t-10">
                    <input
                        id="medicalHistoryOther"
                        v-model="other"
                        class="form-check-input"
                        type="checkbox"
                        @click="other!==other"
                    />
                    <label class="form-check-label" for="medicalHistoryOther">その他</label>
                  </div>

                  <div v-if="other" class="form-check p-l-0 mx-auto">
                    <textarea id="otherText" v-model="otherText" class="form-control" rows="2"></textarea>
                    <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
                  </div>
                </div>
                <div class="row mx-auto m-t-75">
                  <button
                      class="btn btn-light bg-white ashen-link text-decoration-none light border-0 col-12 col-sm-6 order-1 order-sm-0"
                      v-on:click="doCancel(2)">
                    戻る
                  </button>
                  <button
                      class="btn col btn-primary btn-outline-light font-weight-bold col-12 col-sm-6 order-0 order-sm-1"
                      v-on:click="validateAndUpdateUser(4)">
                    登録
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Stepper from 'bs-stepper'
import Common from 'jsadminclient/common'
import {AdminNationalityItems, AdminNationalityItem, SetAdminUpdateUser} from '../assets/jsadminclient/index'
import {apiConfig} from "@/constants";
import Calender from "../components/layouts/Calendar";


const TO_PAGE2 = 2
const TO_PAGE3 = 3
const REGISTER = 4

export default {
  name: "InitialLoginFirst",
  components: {Calender},
  data() {
    return {
      apiConfig: apiConfig,
      nationalityList: [],
      lastName: '',
      firstName: '',
      password: '',
      passwordConfirm: '',
      gender: 3,
      nationalityId: 1,
      address: '',
      phoneNo: '',
      hasCoronaVaccination: 0,
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      latestPcrTestResult: 0,
      latestPcrTestDate: '',
      anemia: false,
      asthma: false,
      heartDisease: false,
      kidneyDisease: false,
      epilepsy: false,
      highBloodPressure: false,
      diabetes: false,
      other: false,
      otherText: null,
      errors: [],
      currentStep: ''
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.currentStep = localStorage.getItem('admin-ft-current-step')
    if (this.currentStep == 2) {
      this.validateAndUpdateUser(this.currentStep)
    } else if (this.currentStep == 3) {
      this.validateAndUpdateUser(this.currentStep)
    }
    window.scrollTo({top: 0, behavior: "auto"})
    this.getNationality()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getNationality()
    }
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetAdminUpdateUser()
      params.initialUpdate = step === REGISTER ? true : false
      params.authenticationPassword = ''
      params.firstName = this.firstName
      params.firstNameUpdate = step === TO_PAGE2 || step === REGISTER ? true : false
      params.lastName = this.lastName
      params.lastNameUpdate = step === TO_PAGE2 || step === REGISTER ? true : false
      params.password = this.password
      params.passwordConfirm = this.passwordConfirm
      params.passwordUpdate = step === TO_PAGE2 || step === REGISTER ? true : false
      params.email = '' // no used
      params.emailUpdate = false
      params.birthday = '' // no used
      params.birthdayUpdate = false
      params.gender = Number(this.gender);
      params.genderUpdate = step === TO_PAGE3 || step === REGISTER ? true : false
      params.nationalityId = this.nationalityId
      params.nationalityIdUpdate = step === TO_PAGE3 || step === REGISTER ? true : false
      params.address = this.address
      params.addressUpdate = step === TO_PAGE3 || step === REGISTER ? true : false
      params.phoneNo = this.phoneNo
      params.phoneNoUpdate = step === TO_PAGE3 || step === REGISTER ? true : false
      // params.hasCoronaVaccination = Number(this.hasCoronaVaccination) === 1 ? true : false
      // params.coronaVaccinationCount = this.coronaVaccinationCount
      // params.latestCoronaVaccinationDate = Common.dateFormatConvert(this.latestCoronaVaccinationDate)
      // params.coronaVaccinationUpdate = step === REGISTER ? true : false
      // params.latestPcrTestResult = this.latestPcrTestResult
      // params.latestPcrTestDate = Common.dateFormatConvert(this.latestPcrTestDate)
      // params.latestPcrTestUpdate = step === REGISTER ? true : false
      params.hasCoronaVaccination = true
      params.coronaVaccinationCount = 2
      params.latestCoronaVaccinationDate = Common.dateFormatConvert("2023/05/01")
      params.coronaVaccinationUpdate = true
      params.latestPcrTestResult = this.latestPcrTestResult
      params.latestPcrTestDate = Common.dateFormatConvert("2023/05/01")
      params.latestPcrTestUpdate = true
      params.medicalHistoryUpdate = step === REGISTER ? true : false
      params.anemia = this.anemia
      params.asthma = this.asthma
      params.heartDisease = this.heartDisease
      params.kidneyDisease = this.kidneyDisease
      params.epilepsy = this.epilepsy
      params.highBloodPressure = this.highBloodPressure
      params.diabetes = this.diabetes
      params.other = this.other
      params.otherText = this.otherText
      
      return params
    },
    /**
     * Validation and registration of input values
     * 1,2,3 is check
     *
     * @param commit
     * @param {Credentials} payload
     * @returns {Promise<Credentials>}
     */
    async validateAndUpdateUser(step) {
      this.currentStep = step
      localStorage.removeItem('admin-ft-current-step')
      localStorage.setItem('admin-ft-current-step', this.currentStep)
      this.errors = []
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const dryRun = step === REGISTER ? 0 : 1
        // if success, empty array
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, dryRun)

        if (step === REGISTER) {
          // success register
          this.$router.push({name: 'Home'})
        } else {
          window.scrollTo({top: 0, behavior: "instant"})
          var stepper1 = new Stepper(document.querySelector('#stepper1'))
          return stepper1.to(step);
        }
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async goBack(step) {
      localStorage.removeItem('admin-ft-current-step')
      this.$router.push({name: 'Login'})
    },
    async doCancel(step) {
      this.currentStep = step
      localStorage.removeItem('admin-ft-current-step')
      localStorage.setItem('admin-ft-current-step', this.currentStep)
      window.scrollTo({top: 0, behavior: "instant"})

      var stepper1 = new Stepper(document.querySelector('#stepper1'))
      return stepper1.to(step);
    },
    async getNationality() {
      const token = this.config.token
      try {
        const api = Common.createSystemApi(token)
        const jsonObject = await api.systemNationalitiesGetAsync()
        const result = AdminNationalityItems.createFromJson(jsonObject)

        // nationality to array
        for (var index = 0; index < result.items.length; index++) {
          const nationality = result.items[index]
          const obj = AdminNationalityItem.createFromJson(nationality)
          this.nationalityList[index] = obj
        }
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    changeNationality() {
      // Nothing to do for now
    },
    updateCoronaDate(date) {
      this.latestCoronaVaccinationDate = date
    },
    updateDate(date) {
      this.latestPcrTestDate = date
    },
  }
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 720px;
  font-size: 16px;
  color: #333333;
}

.active .bs-stepper-circle {
  background-color: #C95D6C;
}

.bs-stepper-header {
  max-width: 300px;
  margin: 0 auto;
}

label {
  line-height: 50px;
  font-weight: 500;
}

.form-check-inline > label {
  line-height: 0;
  font-weight: 500;
}

.form-check-inline {
  margin-right: 40px; //Default: 65px;
}

@media only screen and (max-width: 768px) {
  .form-check-inline {
    margin-right: 20px;
  }
}

.title1 {
  display: none;
}

.title2 {
  display: block;
}

@media (max-width: 576px) {
  .title1 {
    display: block;
  }
  .title2 {
    display: none;
  }
}

.medical-history {
  label {
    line-height: 27px;
  }
}
</style>
